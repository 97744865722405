<template>
  <div>
    <div class="mt-4 text-center">
      <v-dialog v-model="dialog" scrollable width="1400px" persistent>
        <v-card>
          <v-card-title class="primary py-3">
            <span class="headline white--text">Detalle Opex {{ nombreTipoOpex }}</span>
          </v-card-title>
          <v-card-text class="pa-5">
            <v-form ref="form" v-model="validForm" lazy-validation>
              <v-row class="px-2 pt-3 pb-5">
                <v-col cols="12" md="3" sm="6" class="py-0 px-3">
                  <v-autocomplete
                    :items="listadoLocaciones"
                    dense
                    v-model="item.idlocacionbudget"
                    :return-object="false"
                    :rules="[rules.required]"
                    :disabled="idTipoOpex == 2 ? true : false"
                    label="Locación Budget"
                  ></v-autocomplete>
                </v-col>
                <v-col v-if="tipoOpex != 'ti'" cols="12" md="3" sm="6" class="py-0 px-3">
                  <v-text-field
                    label="Descripción Detallada"
                    dense
                    :rules="[rules.required]"
                    v-model="item.descripciondetallada"
                  ></v-text-field>
                </v-col>
                <v-col v-if="tipoOpex == 'camp'" cols="12" md="3" sm="6" class="py-0 px-3">
                  <v-autocomplete
                    :items="listadoGruposServicios"
                    dense
                    :rules="[rules.required]"
                    v-model="item.gruposervicio"
                    :return-object="false"
                    @change="seleccionGrupoServicio"
                    label="Grupo Servicio"
                  ></v-autocomplete>
                </v-col>
                <v-col v-if="tipoOpex != 'ti'" cols="12" md="3" sm="6" class="py-0 px-3">
                  <v-autocomplete
                    :items="listadoServicios"
                    dense
                    :rules="[rules.required]"
                    v-model="item.idservicioconcat"
                    :return-object="false"
                    @change="seleccionServicio"
                    label="Servicio"
                  ></v-autocomplete>
                </v-col>
                <v-col v-if="tipoOpex != 'ti'" cols="12" md="3" sm="6" class="py-0 px-3">
                  <v-autocomplete
                    :items="listadoCuentasMayores"
                    dense
                    :rules="[rules.required]"
                    v-model="item.idcuentamayor"
                    @change="seleccionCuentaMayor"
                    :return-object="false"
                    no-data-text="Seleccione un servicio"
                    label="Cuenta Mayor"
                  ></v-autocomplete>
                  <!--<v-text-field
                    label="Cuenta Mayor"
                    disabled
                    :value="item.servicio.idcuentamayor + ' - ' + item.servicio.CuentaMayor"
                  ></v-text-field>-->
                </v-col>
                <v-col v-if="tipoOpex != 'ti'" cols="12" md="3" sm="6" class="py-0 px-3">
                  <v-text-field
                    label="Cuenta Contable"
                    dense
                    disabled
                    :value="item.idcuentacontable + ' - ' + item.CuentaContable"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3" sm="6" class="py-0 px-3">
                  <v-autocomplete
                    :items="listadoSubAreas"
                    dense
                    v-model="item.idsubarea"
                    :rules="[rules.required]"
                    :return-object="false"
                    @change="cargarCecos"
                    label="Sub Area"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3" sm="6" class="py-0 px-3">
                  <v-autocomplete
                    :items="listadoCecos"
                    dense
                    v-model="item.idceco"
                    @change="seleccionCeco"
                    :rules="[rules.required]"
                    no-data-text="Seleccione una subarea"
                    :return-object="false"
                    label="Ceco"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3" sm="6" class="py-0 px-3">
                  <v-text-field label="Tipo de Gasto" dense disabled :value="item.idtipogastobudget"></v-text-field>
                </v-col>
                <v-col v-if="tipoOpex == 'ti'" cols="12" md="3" sm="6" class="py-0 px-3">
                  <v-autocomplete
                    :items="listadoTiposEquipo"
                    dense
                    v-model="item.idtipoequipo"
                    :rules="[rules.required]"
                    :return-object="false"
                    @change="cargarEquipos"
                    label="Tipo de Equipo"
                  ></v-autocomplete>
                </v-col>
                <v-col v-if="tipoOpex == 'ti'" cols="12" md="3" sm="6" class="py-0 px-3">
                  <v-autocomplete
                    :items="listadoEquipos"
                    dense
                    v-model="item.equipo"
                    :rules="[rules.required]"
                    @change="seleccionEquipo"
                    :return-object="false"
                    no-data-text="Seleccione un tipo de equipo"
                    label="Equipo"
                  ></v-autocomplete>
                </v-col>
                <v-col v-if="item.equipo && tipoOpex == 'ti'" cols="12" md="3" sm="6" class="py-0 px-3">
                  <v-autocomplete
                    :items="listadoGamas"
                    dense
                    v-model="item.gama"
                    :rules="[rules.required]"
                    @change="seleccionGama"
                    :return-object="false"
                    no-data-text="Seleccione un tipo de equipo"
                    label="Gama"
                  ></v-autocomplete>
                  <!--<v-text-field label="Gama" disabled :value="item.equipo.gama"></v-text-field>-->
                </v-col>
                <v-col v-if="item.equipo && tipoOpex == 'ti'" cols="12" md="3" sm="6" class="py-0 px-3">
                  <v-text-field label="Contrato" dense disabled :value="item.contrato"></v-text-field>
                </v-col>
                <v-col cols="12" md="3" sm="6" class="py-0 px-3">
                  <v-autocomplete
                    :items="listadoPeriodos"
                    dense
                    cache-items
                    flat
                    v-model="item.periodo"
                    :rules="[rules.required]"
                    :return-object="false"
                    :multiple="itemParam == null ? true : false"
                    label="Periodo"
                  >
                    <template v-slot:prepend-item v-if="itemParam == null">
                      <v-list-item ripple @click="selectAllPeriodos" @mousedown.prevent>
                        <v-list-item-action>
                          <v-icon color="primary">{{ iconCheckboxAll }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> TODOS </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="3" sm="6" class="py-0 px-3">
                  <v-text-field label="Moneda" dense disabled v-model="item.idmoneda"></v-text-field>
                </v-col>
                <v-col cols="12" md="3" sm="6" class="py-0 px-3">
                  <v-text-field
                    label="Precio"
                    dense
                    :rules="[rules.required]"
                    :disabled="tipoOpex == 'ti' ? true : false"
                    v-model="item.precio"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3" sm="6" class="py-0 px-3">
                  <v-text-field label="Cantidad" dense :rules="[rules.required]" v-model="item.cantidad"></v-text-field>
                </v-col>
                <v-col cols="12" md="3" sm="6" class="py-0 px-3">
                  <v-text-field
                    label="Total"
                    dense
                    disabled
                    :rules="[rules.required]"
                    :value="montoTotal"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3" sm="6" class="py-0 px-3">
                  <v-text-field label="Observaciones" dense v-model="item.observaciones"></v-text-field>
                </v-col>
                <v-col cols="12" md="3" sm="6" class="py-0 px-3">
                  <v-text-field label="Proyecto" dense v-model="item.proyecto"></v-text-field>
                </v-col>
                <v-col cols="12" md="3" sm="6" class="py-0 px-3">
                  <v-select
                    label="Prioridad"
                    clearable
                    clear-icon="mdi-close"
                    dense
                    v-model="item.prioridad"
                    :items="listadoPrioridad"
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
            <v-data-table
              :headers="headersProyeccionMO"
              dense
              :items="listadoProyeccionMO"
              class="border"
              disable-sort
              :mobile-breakpoint="0"
              :items-per-page="-1"
              no-results-text="No se encontraron datos"
              no-data-text="No se encontraron datos"
              :search="search"
              item-class="class"
              :fixed-header="true"
              height="300"
            >
              <template v-slot:top>
                <div class="w-100 d-flex justify-space-between align-center pa-2">
                  <div class="black--text">PROYECCIÓN MO</div>
                  <v-divider class="mx-4" vertical></v-divider>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    class="mt-0 pt-0 text-field-search-matriz"
                    background-color="rgb(220, 220, 220, 0.16)"
                    rounded
                    single-line
                    hide-details
                  ></v-text-field>
                </div>
              </template>
              <template v-slot:[`body`]="{ items }">
                <tbody>
                  <tr v-for="(item, index) in items" :key="index" :class="item.class">
                    <td
                      v-for="(header, index2) in headersProyeccionMO"
                      :key="index2"
                      :class="classProyeccionMO(item, header)"
                    >
                      {{ item[header.value] }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small color="error" text @click="close()"> Cancelar </v-btn>
            <v-btn small color="primary" text @click="guardar()"> Aceptar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div v-if="showLoading" class="progress-background">
        <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
import FormOpexService from '../services/FormOpexService';
import moment from 'moment';
import { decryptAES } from '@/components4x/utils/utils4x';
import Swal from 'sweetalert2';
export default {
  name: 'OpexDetalleForm',
  props: {
    dialog: {
      type: Boolean,
      default: true
    },
    listadoLocacionesParam: {
      type: Array,
      default: new Array()
    },
    listadoSubAreasParam: {
      type: Array,
      default: new Array()
    },
    listadoServiciosParam: {
      type: Array,
      default: new Array()
    },
    listadoGruposServiciosParam: {
      type: Array,
      default: new Array()
    },
    listadoTiposEquipoParam: {
      type: Array,
      default: new Array()
    },
    listadoPeriodos: {
      type: Array,
      default: new Array()
    },
    itemParam: {
      type: Object,
      default: null
    },
    idTipoOpex: {
      type: Number,
      default: null
    },
    tipoOpex: {
      type: String,
      default: ''
    },
    nombreTipoOpex: {
      type: String,
      default: ''
    }
  },
  watch: {
    dialog(dialog) {
      if (dialog) {
        this.listadoServicios = this.listadoServiciosParam;
      }
    },
    async itemParam() {
      if (this.itemParam == null || this.itemParam == {}) {
        this.inicializarItem();
      } else {
        this.showLoading = true;
        let idtipoequipo = this.itemParam.idtipoequipo ? this.itemParam.idtipoequipo.toString() : '';
        let idcuentamayor = this.itemParam.idcuentamayor ? this.itemParam.idcuentamayor.toString() : '';
        this.item = {
          ...this.itemParam,
          idtipoequipo: idtipoequipo,
          idcuentamayor: idcuentamayor
        };
        if (this.idTipoOpex == 1) await this.seleccionGrupoServicio();
        this.item.idservicioconcat =
          this.item.idservicio + '-' + this.item.idcuentacontable + '-' + this.item.idcuentamayor;
        let servicio = this.listadoServicios.find(
          (el) => el.idservicio + '-' + el.idcuentacontable + '-' + el.idcuentamayor == this.item.idservicioconcat
        );
        if (servicio) this.item.CuentaContable = servicio.CuentaContable;
        if (this.idTipoOpex == 1) await this.seleccionServicio();
        else await this.cargarCuentasMayores();
        await this.cargarCecos();
        if (this.idTipoOpex == 5) await this.cargarEquipos();
        if (this.idTipoOpex == 5) await this.cargarGamasPorEquipo();
        this.showLoading = false;
      }
    }
  },
  data: () => ({
    height: 130,
    search: '',
    loading: false,
    service: null,
    showLoading: false,
    archive: null,
    validForm: true,
    rules: {
      required: (value) => !!value || 'Campo requerido.'
      /*min: (v) => v.length >= 8 || 'Min 8 characters',
      emailMatch: () => "The email and password you entered don't match"*/
    },
    expanded: [],
    listado: [],
    item: {},
    listadoEquipos: [],
    listadoCecos: [],
    listadoCuentasMayores: [],
    listadoGamas: [],
    listadoMonedas: [
      { value: 'D', text: 'Dólares' },
      { value: 'S', text: 'Soles' },
      { value: 'E', text: 'Euros' }
    ],
    listadoServicios: [],
    listadoPrioridad: [
      { value: 'BAJA', text: 'BAJA' },
      { value: 'MEDIA', text: 'MEDIA' },
      { value: 'ALTA', text: 'ALTA' }
    ],
    headersProyeccionMO: [
      {
        text: 'LOCACIÓN',
        value: 'idlocacionbudget',
        width: '120',
        class: 'header-items-fixed-ind nth-child1 primary',
        fixed: true
      },
      {
        text: 'CULTIVO ÁREA',
        value: 'cultivoarea',
        width: '160',
        class: 'header-items-fixed-ind nth-child2 primary',
        fixed: true
      },
      { text: 'S1', value: 'S1', width: '100', class: 'primary' },
      { text: 'S2', value: 'S2', width: '100', class: 'primary' },
      { text: 'S3', value: 'S3', width: '100', class: 'primary' },
      { text: 'S4', value: 'S4', width: '100', class: 'primary' },
      { text: 'S5', value: 'S5', width: '100', class: 'primary' },
      { text: 'S6', value: 'S6', width: '100', class: 'primary' },
      { text: 'S7', value: 'S7', width: '100', class: 'primary' },
      { text: 'S8', value: 'S8', width: '100', class: 'primary' },
      { text: 'S9', value: 'S9', width: '100', class: 'primary' },
      { text: 'S10', value: 'S10', width: '100', class: 'primary' },
      { text: 'S11', value: 'S11', width: '100', class: 'primary' },
      { text: 'S12', value: 'S12', width: '100', class: 'primary' },
      { text: 'S13', value: 'S13', width: '100', class: 'primary' },
      { text: 'S14', value: 'S14', width: '100', class: 'primary' },
      { text: 'S15', value: 'S15', width: '100', class: 'primary' },
      { text: 'S16', value: 'S16', width: '100', class: 'primary' },
      { text: 'S17', value: 'S17', width: '100', class: 'primary' },
      { text: 'S18', value: 'S18', width: '100', class: 'primary' },
      { text: 'S19', value: 'S19', width: '100', class: 'primary' },
      { text: 'S20', value: 'S20', width: '100', class: 'primary' },
      { text: 'S21', value: 'S21', width: '100', class: 'primary' },
      { text: 'S22', value: 'S22', width: '100', class: 'primary' },
      { text: 'S23', value: 'S23', width: '100', class: 'primary' },
      { text: 'S24', value: 'S24', width: '100', class: 'primary' },
      { text: 'S25', value: 'S25', width: '100', class: 'primary' },
      { text: 'S26', value: 'S26', width: '100', class: 'primary' },
      { text: 'S27', value: 'S27', width: '100', class: 'primary' },
      { text: 'S28', value: 'S28', width: '100', class: 'primary' },
      { text: 'S29', value: 'S29', width: '100', class: 'primary' },
      { text: 'S30', value: 'S30', width: '100', class: 'primary' },
      { text: 'S31', value: 'S31', width: '100', class: 'primary' },
      { text: 'S32', value: 'S32', width: '100', class: 'primary' },
      { text: 'S33', value: 'S33', width: '100', class: 'primary' },
      { text: 'S34', value: 'S34', width: '100', class: 'primary' },
      { text: 'S35', value: 'S35', width: '100', class: 'primary' },
      { text: 'S36', value: 'S36', width: '100', class: 'primary' },
      { text: 'S37', value: 'S37', width: '100', class: 'primary' },
      { text: 'S38', value: 'S38', width: '100', class: 'primary' },
      { text: 'S39', value: 'S39', width: '100', class: 'primary' },
      { text: 'S40', value: 'S40', width: '100', class: 'primary' },
      { text: 'S41', value: 'S41', width: '100', class: 'primary' },
      { text: 'S42', value: 'S42', width: '100', class: 'primary' },
      { text: 'S43', value: 'S43', width: '100', class: 'primary' },
      { text: 'S44', value: 'S44', width: '100', class: 'primary' },
      { text: 'S45', value: 'S45', width: '100', class: 'primary' },
      { text: 'S46', value: 'S46', width: '100', class: 'primary' },
      { text: 'S47', value: 'S47', width: '100', class: 'primary' },
      { text: 'S48', value: 'S48', width: '100', class: 'primary' },
      { text: 'S49', value: 'S49', width: '100', class: 'primary' },
      { text: 'S50', value: 'S50', width: '100', class: 'primary' },
      { text: 'S51', value: 'S51', width: '100', class: 'primary' },
      { text: 'S52', value: 'S52', width: '100', class: 'primary' }
    ],
    listadoProyeccionMO: []
  }),
  computed: {
    listadoLocaciones() {
      return this.listadoLocacionesParam;
    },
    listadoSubAreas() {
      return this.listadoSubAreasParam;
    },
    listadoGruposServicios() {
      return this.listadoGruposServiciosParam;
    },
    listadoTiposEquipo() {
      return this.listadoTiposEquipoParam;
    },
    montoTotal() {
      let monto = this.item.precio * this.item.cantidad;
      return monto.toFixed(2);
    },
    checkAllPeriodos() {
      //this.item.periodo.hasProperty('length') ? item.periodo.length : 0
      return (this.item.periodo?.length ?? 0) === this.listadoPeriodos.length;
    },
    checkSomePeriodos() {
      return (this.item.periodo?.length ?? 0) > 0 && !this.checkAllPeriodos;
    },
    iconCheckboxAll() {
      if (this.checkAllPeriodos) return 'mdi-close-box';
      if (this.checkSomePeriodos) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    }
  },
  methods: {
    classProyeccionMO(item, header) {
      if (item.isTotal == '1' && header.value == 'cultivoarea') {
        return 'font-weight-black';
      }
      if (item.isTotal == '1' && header.value[0] == 'S') {
        return 'grey lighten-1';
      }
      return '';
    },
    selectAllPeriodos() {
      this.$nextTick(() => {
        if (this.checkAllPeriodos) {
          this.item.periodo = [];
        } else {
          this.item.periodo = this.listadoPeriodos.map((element) => {
            return element.value;
          });
        }
      });
    },
    async inicializarItem() {
      if (this.$refs.form) await this.$refs.form.reset();
      this.item = {
        class: 'class-items-fixed-ind',
        item: '',
        idlocacionbudget: '',
        descripciondetallada: '',
        idservicio: '',
        servicio: {
          value: '',
          text: '',
          idservicio: '',
          Servicio: '',
          idcuentamayor: '',
          CuentaMayor: '',
          idcuentacontable: '',
          CuentaContable: ''
        },
        idtipoequipo: '',
        equipo: {
          value: '',
          text: '',
          equipo: '',
          contrato: '',
          gama: '',
          precio: ''
        },
        idequipo: '',
        gama: '',
        contrato: '',
        idcuentamayor: '',
        idcuentacontable: '',
        CuentaContable: '',
        idsubarea: '',
        idceco: '',
        ceco: { value: '', text: '', idceco: '', ceco: '', textobreve: '', idtipogastobudget: '', tipogasto: '' },
        idtipogastobudget: '',
        periodo: '',
        idmoneda: 'D',
        precio: '',
        cantidad: '',
        monto: '',
        observaciones: '',
        accion: 0,
        activo: 1
      };
      if (this.idTipoOpex == 2) this.item.idlocacionbudget = 'PCK';
    },
    close() {
      this.inicializarItem();
      this.$emit('close');
    },
    async exportar(codigo) {
      this.loading = true;
      const token = localStorage.getItem('token');
      const empresa = decryptAES(localStorage.getItem('emp'));
      await this.matrizService.downloadResource(
        'descargarindicador',
        {
          idempresa: empresa,
          token: token,
          idhn: codigo
        },
        `HN-${codigo}-${moment().format('YYYYMMDDHHMM')}.pdf`
      );
      this.loading = false;
    },
    async alertDialog(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        confirmButtonText: `OK`
      });
    },
    async guardar() {
      if (this.$refs.form.validate()) {
        if (this.tipoOpex != 'ti') {
          /*this.item.idservicio = this.item.servicio.idservicio;
          this.item.idcuentamayor = this.item.servicio.idcuentamayor;
          this.item.idcuentacontable = this.item.servicio.idcuentacontable;*/
        } else {
          /*this.item.equipo = this.item.equipo.equipo;
          this.item.contrato = this.item.gama.Contrato;
          this.item.gama = this.item.gama.Gama;*/
        }
        //this.item.idceco = this.item.ceco.idceco;
        //this.item.idtipogastobudget = this.item.ceco.idtipogastobudget;
        this.item.monto = this.montoTotal;
        this.$emit('guardar', this.item);
        this.close();
      }
    },
    async cargarCecos() {
      this.listadoCecos = [];
      const res = await this.service.get().execResource('cecos', {
        idsubarea: this.item.idsubarea.replace('&', '%26'),
        espacking: this.idTipoOpex == 2 ? 1 : 0
      });
      this.listadoCecos = res.map((element) => {
        return { value: element.idceco, text: element.ceco, ...element };
      });
    },
    async cargarEquipos() {
      this.listadoEquipos = [];
      const res = await this.service.get().execResource('equipos', {
        idtipoequipo: this.item.idtipoequipo.replace('&', '%26')
      });
      this.listadoEquipos = res.map((element) => {
        return { value: element.equipo, text: element.equipo, ...element };
      });
    },
    seleccionCeco() {
      let ceco = this.listadoCecos.find((el) => el.idceco == this.item.idceco);
      this.item.idtipogastobudget = ceco.idtipogastobudget;
    },
    async seleccionGrupoServicio() {
      this.listadoServicios = [];
      const res = await this.service.get().execResource('servicios', { grupo: this.item.gruposervicio });
      this.listadoServicios = res.map((element) => {
        return {
          value: element.idservicio + '-' + element.idcuentacontable + '-' + element.idcuentamayor,
          text: element.Servicio,
          ...element
        };
      });
    },
    async seleccionServicio() {
      let servicio = this.listadoServicios.find(
        (el) => el.idservicio + '-' + el.idcuentacontable + '-' + el.idcuentamayor == this.item.idservicioconcat
      );
      this.item.idservicio = servicio.idservicio;
      this.item.idcuentacontable = servicio.idcuentacontable;
      this.item.CuentaContable = servicio.CuentaContable;
      this.item.precio = servicio.Precio;
      await this.cargarCuentasMayores();
      this.item.idcuentamayor = servicio.idcuentamayor;
    },
    async cargarCuentasMayores() {
      this.listadoCuentasMayores = [];
      console.log(this.item);
      let idservicio = this.item.idservicio ? this.item.idservicio.replace('&', '%26') : '';
      const res = await this.service.get().execResource('cuentasMayores', {
        idservicio: idservicio,
        tipo: 0 //opex
      });
      //console.log(res, 'cuentas mayores');
      this.listadoCuentasMayores = res.map((element) => {
        return { value: element.idcuentamayor, text: element.CuentaMayor, ...element };
      });
    },
    seleccionGama() {
      let gama = this.listadoGamas.find((el) => el.Gama == this.item.gama);
      this.item.contrato = gama.Contrato;
      this.item.precio = Number(gama.Precio).toFixed(2);
    },
    async cargarGamasPorEquipo() {
      this.listadoGamas = [];
      const res = await this.service.get().execResource('gamasPorEquipo', {
        descripcionequipo: this.item.equipo ? this.item.equipo.replace('&', '%26') : ''
      });
      this.listadoGamas = res.map((element) => {
        return { value: element.Gama, text: element.Gama, ...element };
      });
    },
    async seleccionEquipo() {
      await this.cargarGamasPorEquipo();
    },
    async seleccionCuentaMayor() {
      this.item.idservicioconcat =
        this.item.idservicio + '-' + this.item.idcuentacontable + '-' + this.item.idcuentamayor;
    },
    cargarProyeccionMO() {
      this.listadoProyeccionMO = this.$store.getters.listadoProyeccionMO.map((element) => {
        return { class: 'class-items-fixed-ind', ...element };
      });
    }
  },
  async created() {
    this.service = this.$httpService(new FormOpexService(), this);
    this.inicializarItem();
    this.cargarProyeccionMO();
  }
};
</script>
<style lang="scss">
.header-items-fixed-ind {
  position: sticky !important;
  position: -webkit-sticky !important;
}

.header-items-fixed-ind.nth-child1 {
  left: 0;
  z-index: 4 !important;
}
.header-items-fixed-ind.nth-child2 {
  top: 0;
  left: 100px;
  border-right: thin solid rgba(250, 250, 250) !important;
  z-index: 4 !important;
}
.class-items-fixed-ind td:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 3;
}
.class-items-fixed-ind td:nth-child(2) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 100px;
  z-index: 3;
}
.cell-d-none td:last-child {
  display: none;
}
.v-data-table--fixed-header > .v-data-table__wrapper > table > thead > tr > th {
  z-index: 3;
  color: white !important;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr .header-items-fixed-ind {
  background: white;
  color: rgba(0, 0, 0, 0.6);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr .header-items-fixed-ind {
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.7);
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(1) {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(1) {
  background: #1e1e1e;
  color: white;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(2) {
  background: white;
  color: rgba(0, 0, 0, 0.87);
  border-right: thin solid rgba(0, 0, 0, 0.12);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(2) {
  background: #1e1e1e;
  color: white;
  border-right: thin solid rgba(255, 255, 255, 0.12);
}

.text-field-search-matriz.theme--light.v-input input,
.theme--dark.v-input textarea {
  color: rgba(0, 0, 0, 0.87);
}
.text-field-search-matriz.theme--dark.v-input input,
.theme--dark.v-input textarea {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-field-search-matriz .v-input__control .v-input__slot .v-text-field__slot .theme--light.v-label {
  color: rgba(0, 0, 0, 0.6);
}

.text-field-search-matriz .v-input__control .v-input__slot .v-text-field__slot .theme--dark.v-label {
  color: rgba(0, 0, 0, 0.6);
}
</style>
